
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "b-tab-item" })
export default class BTabItem extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(Number) readonly active!: number;
  @Prop(Number) readonly index!: number;

  activate(): void {
    this.$emit("activated", this.index);
  }

  get isActivated(): boolean {
    return this.active === this.index;
  }

  get hasSlot(): boolean {
    return this.$_.has(this.$slots, "item");
  }
}
